import React from "react"

import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const Gallery = ({ data }) => {
  const images = data.allFile.edges.map(pic => ({
    original: pic.node.childImageSharp.fluid.src,
    thumbnail: pic.node.childImageSharp.fixed.src,
    srcSet: pic.node.childImageSharp.fluid.srcSet,
    sizes: pic.node.childImageSharp.fluid.sizes,
  }))

  return (
    <Layout>
      <SEO title="Gallery" />
      <ImageGallery
        items={images}
        showFullscreenButton={false}
        autoPlay={true}
        slideDuration={1000}
      />
    </Layout>
  )
}
export default Gallery

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
